button
{
    position: relative;
    margin: 0;
    padding: 0;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--main-font);
    font-weight: 400;
    font-size: var(--body-font-size);
    background-color: transparent;
    color: var(--color-text);
    border: none;
    -webkit-transition: none var(--control-transition);
    -moz-transition: none var(--control-transition);
    -o-transition: none var(--control-transition);
    transition: none var(--control-transition);
    -webkit-transition-property: background-color, color, border-color, -webkit-box-shadow, opacity, -webkit-filter, font-size, -webkit-transform;
    -moz-transition-property: background-color, color, border-color, -moz-box-shadow, opacity, -moz-filter, font-size, -moz-transform;
    -o-transition-property: background-color, color, border-color, box-shadow, opacity, filter, font-size, -o-transform;
    transition-property: background-color, color, border-color, box-shadow, opacity, filter, font-size, transform;
}

button.user-button
{
    background-color: var(--color-main-menu-text);
    border-radius: 50%;
    width: 2.65rem;
    height: 2.65rem;
    color: var(--color-main-menu-background);
    font-weight: 700;
}

button.menu-button
{
    white-space: nowrap;
    padding: 0 1rem;
    height: 2.5rem;
    width: 100%;
    overflow: hidden;
    justify-content: flex-start;
}

button.image-button,
button:disabled.image-button
{
    background-color: transparent;
    font-size: 1.5rem;
    color: var(--color-text);
}

button.image-button.active
{
    color: var(--color-primary);
}

button.image-button:hover
{
    background-color: transparent !important;
}

button:not(:disabled)
{
    cursor: pointer;
}

button.link-button
{
    background-color: transparent;
    color: var(--color-primary);
    text-decoration: underline;
}

button.primary-button,
button.secondary-button
{
    border-radius: calc(var(--control-height) / 2);
    height: var(--control-height);
    font-size: 1.2rem;
    padding: 0 1.5rem;
}

button.secondary-button
{
    background-color: var(--color-control-background);
    color: var(--color-text);
    border: 2px solid var(--color-primary);
    font-weight: 600;
}

button.secondary-button:disabled
{
    border-color: var(--color-control-disabled);
    opacity: var(--control-disabled-opacity);
    pointer-events: none;
}

button.primary-button
{
    background-color: var(--color-primary);
    color: var(--color-text-inverse);
    width: 100%;
    font-weight: 700;
}

button.primary-button > i,
button.secondary-button > i,
button.menu-button > i:not(.icon-check)
{
    margin-right: 0.75rem;
}

button.menu-button > i
{
    font-size: 1.45rem;
    width: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.menu-button > i.icon-check
{
    margin-left: 1rem;
}

button.menu-button > i.icon-check.hide
{
    opacity: 0;
}

button:not(.secondary-button):disabled
{
    color: var(--color-text-disabled);
    opacity: var(--control-disabled-opacity);
    pointer-events: none;
}

button:not(.link-button):not(.image-button):not(.secondary-button):disabled
{
    background-color: var(--color-control-disabled);
}

button.link-button:disabled
{
    color: var(--color-text);
}

.action-button-container
{
    padding: 1rem var(--page-spacing) 1.6rem;
    flex-shrink: 0;
    margin: 0;
}

button.floating-button
{
    font-size: 1.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: var(--card-border-radius);
    background-color: var(--color-primary);
    color: var(--color-text-inverse);
    -webkit-box-shadow: var(--control-shadow-equal);
    -moz-box-shadow: var(--control-shadow-equal);
    box-shadow: var(--control-shadow-equal);
}

@media (hover: hover)
{
    button:hover:not(:disabled)
    {
        -webkit-filter: brightness(var(--control-hover-brightness));
        -moz-filter: brightness(var(--control-hover-brightness));
        filter: brightness(var(--control-hover-brightness));
    }

    button.secondary-button:hover:not(:disabled),
    button.menu-button:hover:not(:disabled)
    {
        -webkit-filter: none;
        -moz-filter: none;
        filter: none;
    }

    button.secondary-button:hover:not(:disabled)
    {
        color: var(--color-primary);
    }

    button.menu-button:hover:not(:disabled)
    {
        background-color: var(--color-hover-background);
    }
}
