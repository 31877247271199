.ripple-effect > .ripple
{
    position: absolute;
    border-radius: 50%;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-animation: ripple 300ms linear forwards;
    -moz-animation: ripple 300ms linear forwards;
    -o-animation: ripple 300ms linear forwards;
    animation: ripple 300ms linear forwards;
    background-color: #ffffff;
    opacity: 0.6;
    pointer-events: none;
}

.ripple-effect > .ripple.inverse
{
    background-color: #000000;
    opacity: 0.5;
}

.ripple-effect > .ripple.ripple-fill
{
    opacity: 0.4;
    -webkit-animation-duration: 500ms;
    -moz-animation-duration: 500ms;
    -o-animation-duration: 500ms;
    animation-duration: 500ms;
}

.ripple-effect > .ripple.ripple-fill.inverse
{
    opacity: 0.08;
}

.ng-animating .ripple-effect > .ripple
{
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    animation: none !important;
}

@-webkit-keyframes ripple
{
    to
    {
        -webkit-transform: scale(3);
        opacity: 0;
    }
}

@-moz-keyframes ripple
{
    to
    {
        -moz-transform: scale(3);
        opacity: 0;
    }
}

@-o-keyframes ripple
{
    to
    {
        -o-transform: scale(3);
        opacity: 0;
    }
}

@keyframes ripple
{
    to
    {
        transform: scale(3);
        opacity: 0;
    }
}
