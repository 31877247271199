:root
{
    --color-primary: #009ade;
    --color-loader-bright: #7dd8ff;
    --color-loader-dark: var(--color-primary);
    --color-loader-busy: var(--color-section-background);
    --color-background: #f1f4f5;
    --color-background-dark: #000000;
    --color-section-background: #ffffff;
    --color-progress-background: var(--color-primary);
    --color-text: #000000;
    --color-text-inverse: #ffffff;
    --color-text-disabled: #404040;
    --color-danger: #e82020;
    --color-invalid: #ef4949;
    --color-success: #33a856;
    --color-scrollbar: #e1e1e1;
    --color-scrollbar-background: #00000010;
    --color-scrollbar-thumb: #bdc4c6;
    --color-scrollbar-corner: var(--color-background);
    --color-input-border: #dbe2e8;
    --color-control-background: var(--color-section-background);
    --color-control-border: #d2d5d6;
    --color-control-border-hover: #000000;
    --color-control-border-focus: var(--color-primary);
    --color-control-disabled: #d1d0d2;
    --color-placeholder: #a09f9f;
    --color-placeholder-background: #eaecee;
    --color-selection: #dfe2e8;
    --color-overlay: #00000080;
    --color-hover-background: #eaf2fb;
    --color-highlight-background: #cfecf9;
    --color-clock-background: #f0f2f4;
    --color-alternate-row-background: #ebf0f1;
    --color-main-menu-background: #008dcb;
    --color-main-menu-text: var(--color-text-inverse);
    --color-main-menu-selected-background: #017aae;
    --color-camera-button-border: var(--color-text);
    --color-camera-button-background: #b9bec0;
    --color-scanner-background: #000000;
    --color-scanner-foreground: #ffffff;
    --color-arrival-ontime: #54c136;
    --color-arrival-late: #fd3132;
    --color-arrival-unknown: #bdad71;
    --color-plan-marker: #00a3e8;
    --color-plan-end: #00ca47;
    --color-plan-current: #fb7538;
    --color-plan-current-background: #fde3d7;
    --color-event-temperature: #ff538f;
    --color-event-humidity: #036adc;
    --color-event-security: #f1c20a;
    --color-event-impact: #d96c00;
    --color-event-stop: #fd3132;
    --color-event-time-exceeded: #ef4949;
    --color-chart-text: var(--color-text);
    --color-chart-lines: #e0e0e0;
    --color-chart-axis: #c6c8c8;
    --color-chart-border: #999999;
    --color-chart-min-threshold: #000000;
    --color-chart-max-threshold: #ff0000;
    --color-chart-annotaion: #000000;
    --color-chart-zoom-area: var(--color-primary);
    --color-map-perimeter: var(--color-primary);
    --color-map-timeline-line: var(--color-primary);
    --color-map-timeline-point: #cf54fe;
    --color-title-border: #00000080;
    --color-list-border: #000000;
    --color-list-header-background: #e6e8ea;
    --color-list-row-border: #e4ecf5;
    --color-list-row-background: #ffffff;
    --color-list-odd-row-background: var(--color-hover-background);
    --color-slider-background: #e6e8ea;
    --color-dropdown-border: #d3e0e5;
    --color-portfolio-backdrop: #000000e0;
    --control-height: 3.375rem;
    --control-hover-brightness: 110%;
    --control-spacing: 1.5rem;
    --control-padding: 1rem;
    --control-search-padding: 1.5rem;
    --group-spacing: 1.5rem;
    --section-spacing: 1rem;
    --section-padding: 1rem;
    --expander-width: 3rem;
    --expander-height: 1.5rem;
    --mobile-panel-max-width: 92%;
    --control-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    --control-shadow-up: 0 -3px 6px rgba(0, 0, 0, 0.16);
    --control-shadow-equal: 0 0 3px rgba(0, 0, 0, 0.2);
    --control-shadow-inset: 0px 3px 6px inset rgba(0,0,0,0.1);
    --modal-shadow: 0 0 26px rgba(0, 0, 0, 0.5);
    --popup-shadow: 0 0 26px rgba(0, 0, 0, 0.25);
    --popup-light-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
    --dropdown-shadow: 0px 2px 6px rgba(0,0,0,0.2);
    --dropdown-shadow-up: 0px -2px 6px rgba(0,0,0,0.2);
    --dropdown-shadow-equal: 0px 0px 6px rgba(0,0,0,0.2);
    --border-shadow: 0px 0px 2px inset rgba(0,0,0,0.3);
    --frame-shadow: 0px 0px 0px 1px inset var(--color-primary);
    --frame-thick-shadow: 0px 0px 0px 3px inset var(--color-primary);
    --hard-shadow: 0px 0px 10px #000000;
    --text-shadow: 0 0 2px black, 0 0 2px black;
    --text-shadow-inverse: 0 0 2px var(--color-background), 0 0 2px var(--color-background);
    --filter-main-menu-shadow: drop-shadow(2px 0px 4px rgba(0,0,0,0.25));
    --filter-login-shadow: drop-shadow(0px 0px 26px rgba(0,0,0,0.5));
    --pin-marker-height-ratio: 46 / 32;
    --page-spacing: 1.5rem;
    --top-bar-height: 3.75rem;
    --side-bar-width: 7rem;
    --side-bar-collapse-width: 3.5rem;
    --control-disabled-opacity: 0.4;
    --control-border-radius: 0.5rem;
    --control-transition: 0.25s ease-in-out;
    --control-transition-linear: 0.2s linear;
    --normal-transition: 0.5s ease-in-out;
    --short-transition: 0.15s ease-in-out;
    --card-border-radius: 0.75rem;
    --modal-border-radius: 1rem;
    --large-border-radius: 2rem;
    --popup-arrow-width: 1.25em;
    --popup-arrow-height: 0.815em;
    --popup-vertical-offset: 0.1em;
    --popup-horizontal-offset: 0.4em;
    --placeholder-top: calc(var(--control-height) / 3.85);
    --label-transform-y: calc(-1 * var(--control-height) / 2.35);
    --label-scale: 0.8;
    --open-top-offset: 0.45rem;
    --option-height: 2.73015873rem;
    --list-row-height: 3.75rem;
    --list-header-height: 3.75rem;
    --dropdown-item-height: 2.5rem;
    --disabled-opacity: 0.5;
    --login-shadow-opacity: 0.45;
    --loader-z-index: 100;
    --modal-z-index: 1000;
    --dropdown-z-index: 10000;
    --menubar-submenu-z-index: 100000;
    --menubar-z-index: 1000000;
    --info-font-size: 1rem;
    --body-font-size: 1.125rem;
    --scrollbar-size: 7px;
    --main-font: 'Quicksand', sans-serif;
    --title-font: 'Quicksand', sans-serif;
}

html
{
    font-size: 1rem;
    width: 100%;
    height: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

html.dark
{
    --color-primary: #3981c9;
    --color-loader-bright: #5c93cf;
    --color-loader-dark: #3777bd;
    --color-loader-busy: var(--color-text);
    --color-background: #1e2022;
    --color-background-dark: #000000;
    --color-section-background: #17181a;
    --color-text: #b0b0b0;
    --color-text-inverse: #e8e8e8;
    --color-text-disabled: #000000;
    --color-placeholder: #898a86;
    --color-placeholder-background: #242629;
    --color-selection: #40423e;
    --color-scrollbar-background: #00000010;
    --color-scrollbar-thumb: #3b3f45;
    --color-scrollbar-corner: #181a1b;
    --color-input-border: #dbe2e8;
    --color-control-border: #3c4144;
    --color-control-border-hover: #515c5e;
    --color-control-disabled: #86888a;
    --color-hover-background: #202226;
    --color-highlight-background: #183746;
    --color-clock-background: #303234;
    --color-alternate-row-background: #ebf0f1;
    --color-main-menu-background: #12375c;
    --color-main-menu-text: #d0d0d0;
    --color-main-menu-selected-background: #1e5a96;
    --color-camera-button-border: #53595e;
    --color-plan-current-background: #71381f;
    --color-chart-text: var(--color-text);
    --color-chart-lines: #282f33;
    --color-chart-axis: #c6c8c8;
    --color-chart-border: #999999;
    --color-chart-min-threshold: #ffffff;
    --color-chart-max-threshold: #e82020;
    --color-chart-annotaion: #ffffff;
    --color-title-border: #ffffff40;
    --color-list-border: #000000;
    --color-list-header-background: #26282c;
    --color-list-row-border: #283132;
    --color-list-row-background: #181a1c;
    --color-list-odd-row-background: var(--color-hover-background);
    --color-slider-background: #24292a;
    --color-dropdown-border: #2a2e30;
    --control-shadow: 0 3px 6px rgba(0, 0, 0, 0.5);
    --control-shadow-up: 0 -3px 6px rgba(0, 0, 0, 0.5);
    --popup-light-shadow: 0 0 3px rgba(255, 255, 255, 0.05);
    --popup-menu-shadow: 0 0 3px rgba(255, 255, 255, 0.05);
    --popup-shadow: 0 0 6px rgba(0, 0, 0, 0.25);
    --filter-main-menu-shadow: drop-shadow(2px 0px 4px rgba(0,0,0,0.45));
    --filter-login-shadow: drop-shadow(0px 0px 26px rgba(0,0,0,1));
}

body
{
    font-family: var(--main-font);
    font-weight: 400;
    font-size: var(--body-font-size);
    line-height: 1.35;
    margin: 0;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    overscroll-behavior: none;
    background-color: var(--color-background);
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body.barcode-scanner-active
{
    background-color: transparent !important;
}

body app-root
{
    -webkit-transition: opacity var(--normal-transition);
    -moz-transition: opacity var(--normal-transition);
    -o-transition: opacity var(--normal-transition);
    transition: opacity var(--normal-transition);
}

body.barcode-scanner-active app-root
{
    opacity: 0;
    pointer-events: none;
}

*,
*::before,
*::after
{
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-drag: none;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    outline: none;
}

a
{
    color: var(--color-primary);
    font-weight: 500;
}

b
{
    font-weight: bold;
}

@media (prefers-reduced-motion: no-preference)
{
    :root
    {
        scroll-behavior: smooth;
    }
}

::-moz-selection
{
    background: var(--color-selection);
    text-shadow: none;
}

::selection
{
    background: var(--color-selection);
    text-shadow: none;
}

.select-none, .cgimarker-data,
label, img, button, input::placeholder
{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.no-margin
{
    margin: 0 !important;
}

.align-inherit
{
    text-align: inherit !important;
}

.animation-overflow.ng-animating
{
    overflow: hidden !important;
}

.flex
{
    display: flex;
    align-items: center;
}

.auto-width
{
    width: auto !important;
}

.no-shadow
{
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}

.overlay
{
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-overlay);
}

.small-units
{
    font-size: 0.85rem;
}

.remark-label
{
    font-weight: 500;
    font-size: 0.9rem;
    margin: 0.15rem 0 0 0.15rem;
    color: var(--color-placeholder);
}

.busy-support.busy
{
    pointer-events: none;
}

.busy-support *
{
    -webkit-transition: opacity var(--control-transition);
    -moz-transition: opacity var(--control-transition);
    -o-transition: opacity var(--control-transition);
    transition: opacity var(--control-transition);
}

.busy-support.busy *
{
    opacity: 0.4;
}

.busy-support .icon-spinner
{
    opacity: 0.7;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

html:not(.mobile) body > modal-message > .modal-content-container
{
    left: calc(var(--side-bar-width) / 2);
}

html:not(.mobile) porfolio-viewer .viewer-container
{
    left: calc(var(--side-bar-width) - 0.5rem);
}

@media only screen and (max-width: 1920px)
{
    html:not(.mobile)
    {
        font-size: 0.875rem;
    }
}

@media (-webkit-min-device-pixel-ratio: 1.25) and (max-width: 1920px)
{
    html:not(.mobile)
    {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 1000px)
{
    html:not(.mobile)
    {
        font-size: 0.8125rem;
    }
}

@media only screen and (max-width: 800px)
{
    html:not(.mobile)
    {
        font-size: 0.75rem;
    }
}

@media only screen and (max-width: 404px)
{
    html.mobile
    {
        font-size: 0.9375rem;
    }
}

@media only screen and (max-width: 384px)
{
    html.mobile
    {
        font-size: 0.875rem;
    }
}

@media only screen and (max-width: 362px)
{
    html.mobile
    {
        font-size: 0.8125rem;
    }
}

@media only screen and (max-width: 342px)
{
    html.mobile
    {
        font-size: 0.75rem;
    }
}

@media only screen and (max-width: 304px)
{
    html.mobile
    {
        font-size: 0.7rem;
    }
}

@media only screen and (max-width: 304px)
{
    html
    {
        font-size: 0.6875rem;
    }
}

@media only screen and (max-width: 284px)
{
    html.mobile
    {
        font-size: 0.65rem;
    }
}

@media only screen and (max-width: 284px)
{
    html
    {
        font-size: 0.625rem;
    }
}
