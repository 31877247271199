@font-face
{
    font-family: 'icomoon';
    src: url('fonts/icomoon.woff?ye2ru9') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"]
{
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-collapse-left:before
{
    content: "\e900";
}

.icon-cancel:before
{
    content: "\e901";
}

.icon-checkbox:before
{
    content: "\e902";
}

.icon-checkbox-check:before
{
    content: "\e903";
}

.icon-error:before
{
    content: "\e904";
}

.icon-info:before
{
    content: "\e905";
}

.icon-logout:before
{
    content: "\e906";
}

.icon-menu:before
{
    content: "\e907";
}

.icon-question:before
{
    content: "\e908";
}

.icon-refresh:before
{
    content: "\e909";
}

.icon-search:before
{
    content: "\e90a";
}

.icon-spinner:before
{
    content: "\e90b";
}

.icon-check:before
{
    content: "\e90c";
}

.icon-user:before
{
    content: "\e90d";
}

.icon-eye:before
{
    content: "\e90e";
}

.icon-eye-off:before
{
    content: "\e90f";
}

.icon-arrow-down:before
{
    content: "\e910";
}

.icon-arrow-right:before
{
    content: "\e911";
}

.icon-radio:before
{
    content: "\e912";
}

.icon-radio-check:before
{
    content: "\e913";
}

.icon-switch-off:before
{
    content: "\e914";
}

.icon-switch-on:before
{
    content: "\e915";
}

.icon-face-frown:before
{
    content: "\e916";
}

.icon-sort-direction:before
{
    content: "\e917";
}

.icon-calendar-lines:before
{
    content: "\e918";
}

.icon-center:before
{
    content: "\e919";
}

.icon-container-crane:before
{
    content: "\e91a";
}

.icon-container-alt:before
{
    content: "\e91b";
}

.icon-clock:before
{
    content: "\e91c";
}

.icon-calendar-clock:before
{
    content: "\e91d";
}

.icon-arrow-up:before
{
    content: "\e91e";
}

.icon-keyboard:before
{
    content: "\e91f";
}

.icon-arrow-down-light:before
{
    content: "\e920";
}

.icon-colon:before
{
    content: "\e921";
}

.icon-paperclip:before
{
    content: "\e922";
}

.icon-list-ul:before
{
    content: "\e923";
}

.icon-image-slash:before
{
    content: "\e924";
}

.icon-camera:before
{
    content: "\e925";
}

.icon-image:before
{
    content: "\e926";
}

.icon-filter:before
{
    content: "\e927";
}

.icon-share-nodes:before
{
    content: "\e928";
}

.icon-reset:before
{
    content: "\e929";
}

.icon-lock-open:before
{
    content: "\e92a";
}

.icon-temperature-full:before
{
    content: "\e92b";
}

.icon-droplet:before
{
    content: "\e92c";
}

.icon-explosion-solid:before
{
    content: "\e92d";
}

.icon-signal-stream:before
{
    content: "\e92e";
}

.icon-target:before
{
    content: "\e92f";
}

.icon-location-dot:before
{
    content: "\e930";
}

.icon-more-info:before
{
    content: "\e931";
}

.icon-sort:before
{
    content: "\e932";
}

.icon-sort-down:before
{
    content: "\e933";
}

.icon-grid:before
{
    content: "\e934";
}

.icon-edit:before
{
    content: "\e935";
}

.icon-collapse-down:before
{
    content: "\e936";
}

.icon-display-chart-up:before
{
    content: "\e937";
}

.icon-more:before
{
    content: "\e938";
}

.icon-filter-circle-xmark:before
{
    content: "\e939";
}

.icon-arrow-left:before
{
    content: "\e93a";
}

.icon-warehouse:before
{
    content: "\e93b";
}

.icon-container:before
{
    content: "\e93c";
}

.icon-waypoint:before
{
    content: "\e93d";
}

.icon-destination-port:before
{
    content: "\e93e";
}

.icon-earth-americas:before
{
    content: "\e93f";
}

.icon-map:before
{
    content: "\e940";
}

.icon-flag-checkered:before
{
    content: "\e941";
}

.icon-no-device:before
{
    content: "\e942";
}

.icon-route:before
{
    content: "\e943";
}

.icon-temperature-arrow-up:before
{
    content: "\e944";
}

.icon-temperature-arrow-down:before
{
    content: "\e945";
}

.icon-auto-close:before
{
    content: "\e946";
}

.icon-search-auto-close:before
{
    content: "\e947";
}

.icon-airport:before
{
    content: "\e948";
}

.icon-train-station:before
{
    content: "\e949";
}

.icon-arrow-down-thin:before
{
    content: "\e94a";
}

.icon-drag:before
{
    content: "\e94b";
}

.icon-zoom-in:before
{
    content: "\e94c";
}

.icon-street-view:before
{
    content: "\e94d";
}

.icon-hand:before
{
    content: "\e94e";
}

.icon-hourglass-end:before
{
    content: "\e94f";
}

.icon-hourglass-start:before
{
    content: "\e950";
}

.icon-hourglass-empty:before
{
    content: "\e951";
}

.icon-menu-alt:before
{
    content: "\e952";
}

.icon-lock:before
{
    content: "\e953";
}

.icon-router:before
{
    content: "\e954";
}

.icon-stopwatch:before
{
    content: "\e955";
}

.icon-gear:before
{
    content: "\e956";
}

.icon-chevron-right:before
{
    content: "\e957";
}

.icon-copy:before
{
    content: "\e958";
}

.icon-expand:before
{
    content: "\e959";
}

.icon-collapse:before
{
    content: "\e95a";
}

.icon-edit-alt:before
{
    content: "\e95b";
}

.icon-drag-drop:before
{
    content: "\e95c";
}

.icon-chart-mixed:before
{
    content: "\e95d";
}

.icon-truck-container:before
{
    content: "\e95e";
}

.icon-bell-regular:before
{
    content: "\e95f";
}

.icon-insights-menu:before
{
    content: "\e960";
}

.icon-inventory:before
{
    content: "\e961";
}

.icon-calendar-success:before
{
    content: "\e962";
}

.icon-delete:before
{
    content: "\e963";
}

.icon-warehouse-alt:before
{
    content: "\e964";
}

.icon-flag:before
{
    content: "\e965";
}

.icon-excel:before
{
    content: "\e966";
}

.icon-demurrage:before
{
    content: "\e967";
}

.icon-demurrage-placeholder:before
{
    content: "\e968";
}

.icon-demurrage-monitoring:before
{
    content: "\e969";
}

.icon-ship:before
{
    content: "\e96a";
}

.icon-event-monitoring:before
{
    content: "\e96b";
}

.icon-door-open:before
{
    content: "\e96c";
}

.icon-time-based-humidity:before
{
    content: "\e96d";
}

.icon-time-based-temperature:before
{
    content: "\e96e";
}

.icon-shipment-manager:before
{
    content: "\e96f";
}

.icon-start-shipment:before
{
    content: "\e970";
}

.icon-palette:before
{
    content: "\e971";
}

.icon-moon:before
{
    content: "\e972";
}

.icon-sun-bright:before
{
    content: "\e973";
}

.icon-headset:before
{
    content: "\e974";
}

.icon-search-pending:before
{
    content: "\e975";
}

.icon-envelope:before
{
    content: "\e976";
}

.icon-envelope-open:before
{
    content: "\e977";
}

.icon-barcode-read:before
{
    content: "\e978";
}

.icon-lightbulb:before
{
    content: "\e979";
}

.icon-lightbulb-on:before
{
    content: "\e97a";
}

.icon-inbox:before
{
    content: "\e97b";
}

.icon-bluetooth:before
{
    content: "\e97c";
}

.icon-alert-manager:before
{
    content: "\e97d";
}
