@-moz-document url-prefix()
{
    body,
    virtual-list
    {
        scrollbar-width: thin;
        scrollbar-color: var(--color-scrollbar-thumb) var(--color-scrollbar);
    }
}

::-webkit-scrollbar
{
    -webkit-appearance: none;
    background-color: var(--color-scrollbar-background);
    border-radius: calc(var(--scrollbar-size) / 2);
}

::-webkit-scrollbar:vertical
{
    width: var(--scrollbar-size);
}

::-webkit-scrollbar:horizontal
{
    height: var(--scrollbar-size);
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:window-inactive
{
    background-color: var(--color-scrollbar-thumb);
    border-radius: calc(var(--scrollbar-size) / 2);
}

::-webkit-scrollbar-corner
{
    background-color: var(--color-scrollbar-corner);
}

.overlay-scrollbar-container
{
    -ms-overflow-style: none; /* IE 11 */
    scrollbar-width: none; /* Firefox 64 */
}

.overlay-scrollbar-container::-webkit-scrollbar
{
    display: none;
}

@media only screen and (orientation:portrait)
{
    :root
    {
        --scrollbar-size: 0.2rem;
    }
}

ovarlay-scrollbar
{
    display: block;
    width: 100%;
    position: relative;
}

ovarlay-scrollbar .overlay-scrollbar-root
{
    -webkit-transform: none !important;
    -moz-transform: none !important;
    -o-transform: none !important;
    transform: none !important;
}

.overlay-scrollbar-root
{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: opacity var(--control-transition);
    -moz-transition: opacity var(--control-transition);
    -o-transition: opacity var(--control-transition);
    transition: opacity var(--control-transition);
    overflow: hidden;
    z-index: 1;
    pointer-events: none;
}

.overlay-scrollbar
{
    overflow: hidden;
    opacity: 0;
    -webkit-transition: opacity var(--control-transition);
    -moz-transition: opacity var(--control-transition);
    -o-transition: opacity var(--control-transition);
    transition: opacity var(--control-transition);
    position: absolute;
}

.overlay-scrollbar.active
{
    pointer-events: auto;
    opacity: 1;
}

.overlay-scrollbar .overlay-scrollbar-thumb
{
    background-color: var(--color-scrollbar-thumb);
    -webkit-transition: none var(--short-transition);
    -moz-transition: none var(--short-transition);
    -o-transition: none var(--short-transition);
    transition: none var(--short-transition);
}

.overlay-scrollbar.vertical .overlay-scrollbar-thumb
{
    -webkit-transition-property: height, -webkit-filter;
    -moz-transition-property: height, -moz-filter;
    -o-transition-property: height, filter;
    transition-property: height, filter;
}

.overlay-scrollbar.horizontal .overlay-scrollbar-thumb
{
    -webkit-transition-property: width, -webkit-filter;
    -moz-transition-property: width, -moz-filter;
    -o-transition-property: width, filter;
    transition-property: width, filter;
}

.overlay-scrollbar,
.overlay-scrollbar .overlay-scrollbar-thumb
{
    border-radius: calc(var(--scrollbar-size) / 2);
    outline: none;
}

.overlay-scrollbar.vertical,
.overlay-scrollbar.vertical .overlay-scrollbar-thumb
{
    width: var(--scrollbar-size);
}

.overlay-scrollbar.vertical
{
    top: 0;
    right: 0;
    height: 100%;
    background-color: var(--color-scrollbar-background);
}

.overlay-scrollbar.horizontal,
.overlay-scrollbar.horizontal .overlay-scrollbar-thumb
{
    height: var(--scrollbar-size);
}

.overlay-scrollbar.horizontal
{
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-scrollbar-background);
}

@media (hover: hover)
{
    html .overlay-scrollbar .overlay-scrollbar-thumb:hover
    {
        --control-hover-brightness: 106%;
        -webkit-filter: brightness(var(--control-hover-brightness));
        -moz-filter: brightness(var(--control-hover-brightness));
        filter: brightness(var(--control-hover-brightness));
    }

    html.dark .overlay-scrollbar .overlay-scrollbar-thumb:hover
    {
        --control-hover-brightness: 115%;
        -webkit-filter: brightness(var(--control-hover-brightness));
        -moz-filter: brightness(var(--control-hover-brightness));
        filter: brightness(var(--control-hover-brightness));
    }
}
