.popup-container
{
    cursor: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity var(--control-transition);
    -moz-transition: opacity var(--control-transition);
    -o-transition: opacity var(--control-transition);
    transition: opacity var(--control-transition);
    pointer-events: none;
}

.popup-container.tooltip
{
    position: fixed;
    width: auto;
    height: auto;
    z-index: var(--dropdown-z-index);
}

.popup-container.tooltip.pointer-inactive
{
    pointer-events: none !important;
}

.popup-container.active
{
    opacity: 1;
    pointer-events: auto;
}

.popup-container.animate
{
    -webkit-transition-property: top, left, opacity;
    -moz-transition-property: top, left, opacity;
    -o-transition-property: top, left, opacity;
    transition-property: top, left, opacity;
}

.popup-bubble
{
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(-50%, -100%);
    -moz-transform: translate(-50%, -100%);
    -o-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    background-color: var(--color-section-background);
    border-radius: var(--modal-border-radius);
    padding: 0.75rem 0;
    -webkit-filter: drop-shadow(var(--popup-light-shadow));
    -moz-filter: drop-shadow(var(--popup-light-shadow));
    filter: drop-shadow(var(--popup-light-shadow));
    -webkit-transition: none var(--control-transition);
    -moz-transition: none var(--control-transition);
    -o-transition: none var(--control-transition);
    transition: none var(--control-transition);
    max-width: 80vw;
}

.popup-bubble .popup-bubble-content
{
    padding: 0.5rem 1.5rem;
    max-width: 35rem;
}

.popup-container.tooltip .popup-bubble .popup-bubble-content
{
    width: max-content;
    height: max-content;
    max-height: 12.5rem;
    overflow: hidden auto;
    padding: 0.5rem 1.25rem;
}

.popup-container.animate .popup-bubble
{
    -webkit-transition-property: top, left;
    -moz-transition-property: top, left;
    -o-transition-property: top, left;
    transition-property: top, left;
}

.popup-bubble.top.right
{
    left: -1em;
    top: 1em;
}

.popup-bubble.top.left
{
    left: 1em;
    top: 1em;
}

.popup-bubble.bottom.right
{
    left: -0.75em;
    top: 0.25em;
}

.popup-bubble.bottom.left
{
    left: 0.75em;
    top: 0.25em;
}

.popup-bubble::after
{
    content: '';
    position: absolute;
    width: var(--popup-arrow-width);
    height: var(--popup-arrow-height);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 23 15'%3E%3Cpath d='m 7.2277006,12.554044 c 1.8951224,3.291702 6.6329274,3.291702 8.5280504,0 L 22.983451,0 H 0'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    left: 50%;
    top: calc(100% - var(--popup-vertical-offset));
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    -webkit-transition: none var(--control-transition);
    -moz-transition: none var(--control-transition);
    -o-transition: none var(--control-transition);
    transition: none var(--control-transition);
}

html.dark .popup-bubble::after
{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2317181a' viewBox='0 0 23 15'%3E%3Cpath d='m 7.2277006,12.554044 c 1.8951224,3.291702 6.6329274,3.291702 8.5280504,0 L 22.983451,0 H 0'/%3E%3C/svg%3E");
}

.popup-container.animate .popup-bubble::after
{
    -webkit-transition-property: top, left, bottom, right;
    -moz-transition-property: top, left, bottom, right;
    -o-transition-property: top, left, bottom, right;
    transition-property: top, left, bottom, right;
}

.popup-bubble.bottom::after
{
    top: auto;
    bottom: calc(100% - var(--popup-vertical-offset));
    -webkit-transform: translate(-50%, 0) scaleY(-1);
    -moz-transform: translate(-50%, 0) scaleY(-1);
    -o-transform: translate(-50%, 0) scaleY(-1);
    transform: translate(-50%, 0) scaleY(-1);
}

.popup-bubble.right::after
{
    top: 50%;
    right: calc(100% - var(--popup-horizontal-offset));
    left: auto;
    -webkit-transform: translate(0, -50%) rotate(90deg);
    -moz-transform: translate(0, -50%) rotate(90deg);
    -o-transform: translate(0, -50%) rotate(90deg);
    transform: translate(0, -50%) rotate(90deg);
}

.popup-bubble.left::after
{
    top: 50%;
    left: calc(100% - var(--popup-horizontal-offset));
    -webkit-transform: translate(0, -50%) rotate(270deg);
    -moz-transform: translate(0, -50%) rotate(270deg);
    -o-transform: translate(0, -50%) rotate(270deg);
    transform: translate(0, -50%) rotate(270deg);
}

.popup-bubble.top.right::after
{
    top: 100%;
    right: 100%;
    left: auto;
    -webkit-transform: translate(0.85em, -0.65em) rotate(45deg);
    -moz-transform: translate(0.85em, -0.65em) rotate(45deg);
    -o-transform: translate(0.85em, -0.65em) rotate(45deg);
    transform: translate(0.85em, -0.65em) rotate(45deg);
}

.popup-bubble.top.left::after
{
    top: 100%;
    left: 100%;
    -webkit-transform: translate(-0.85em, -0.65em) rotate(315deg);
    -moz-transform: translate(-0.85em, -0.65em) rotate(315deg);
    -o-transform: translate(-0.85em, -0.65em) rotate(315deg);
    transform: translate(-0.85em, -0.65em) rotate(315deg);
}

.popup-bubble.bottom.right::after
{
    top: 0;
    right: 100%;
    left: auto;
    -webkit-transform: translate(0.85em, -0.25em) rotate(135deg);
    -moz-transform: translate(0.85em, -0.25em) rotate(135deg);
    -o-transform: translate(0.85em, -0.25em) rotate(135deg);
    transform: translate(0.85em, -0.25em) rotate(135deg);
}

.popup-bubble.bottom.left::after
{
    top: 0;
    left: 100%;
    -webkit-transform: translate(-0.85em, -0.25em) rotate(225deg);
    -moz-transform: translate(-0.85em, -0.25em) rotate(225deg);
    -o-transform: translate(-0.85em, -0.25em) rotate(225deg);
    transform: translate(-0.85em, -0.25em) rotate(225deg);
}
