[id^=__lpform], [data-lastpass-icon-root]
{
    display: none !important;
}

form
{
    width: 100%;
    padding: 0;
    flex-grow: 1;
    height: 0;
}

fieldset
{
    border: none;
    margin: 0;
    padding: 0;
    min-inline-size: auto;
}

form > fieldset
{
    height: 100%;
}

form .form-content-wrapper
{
    display: flex;
    flex-direction: column;
    height: 100%;
}

form .form-content
{
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 1rem;
    padding-top: 1rem;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus,
.form-control:-webkit-autofill:active
{
    -webkit-box-shadow: 0 0 0 30px var(--color-control-background) inset !important;
    animation-name: webkit-autofill;
    -webkit-animation-fill-mode: both;
}

.form-control:-webkit-autofill
{
    -webkit-text-fill-color: var(--color-text) !important;
}

@-webkit-keyframes webkit-autofill
{
    to
    {
        color: var(--color-text);
        background: transparent;
    }
}

@keyframes webkit-autofill
{
    to
    {
        color: var(--color-text);
        background: transparent;
    }
}

.form-group
{
    margin-bottom: 1.5rem;
    position: relative;
}

.horizontal-groups
{
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: minmax(0, 1fr);
    grid-column-gap: 1rem;
    width: 100%;
    position: relative;
}

.horizontal-groups .horizontal-group
{
    position: relative;
}

input[type="number"]
{
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
{
    -webkit-appearance: none;
    margin: 0;
}

input[type="file"]
{
    display: none;
}

input[type="text"]::-webkit-input-placeholder,
input[type='number']::-webkit-input-placeholder,
input[type='date']::-webkit-input-placeholder,
input[type='tel']::-webkit-input-placeholder,
input[type='email']::-webkit-input-placeholder,
input[type='password']::-webkit-input-placeholder,
textarea::-webkit-input-placeholder
{
    color: var(--color-placeholder) !important;
    font-size: var(--placeholder-font-size);
}

input[type="text"]::-moz-placeholder,
input[type='number']::-moz-placeholder,
input[type='date']::-moz-placeholder,
input[type='tel']::-moz-placeholder,
input[type='email']::-moz-placeholder,
input[type='password']::-moz-placeholder,
textarea::-moz-placeholder
{
    color: var(--color-placeholder) !important;
    font-size: var(--placeholder-font-size);
}

input[type="text"]:-ms-input-placeholder,
input[type='number']:-ms-input-placeholder,
input[type='date']:-ms-input-placeholder,
input[type='tel']:-ms-input-placeholder,
input[type='email']:-ms-input-placeholder,
input[type='password']:-ms-input-placeholder,
textarea:-ms-input-placeholder
{
    color: var(--color-placeholder) !important;
    font-size: var(--placeholder-font-size);
}

input[type=time]::-webkit-datetime-edit-hour-field:focus,
input[type=time]::-webkit-datetime-edit-minute-field:focus,
input[type=time]::-webkit-datetime-edit-second-field:focus,
input[type=time]::-webkit-datetime-edit-ampm-field:focus
{
    background-color: var(--color-selection);
    color: var(--color-text);
}

.form-control
{
    background: var(--color-control-background);
    height: var(--control-height);
    width: 100%;
    border-radius: var(--control-border-radius);
    outline: none;
    border: 1px solid var(--color-control-border);
    padding-left: var(--control-padding);
    font-family: var(--main-font);
    font-size: var(--body-font-size);
    color: var(--color-text) !important;
    transition: none var(--control-transition);
    -webkit-transition: none var(--control-transition);
    -moz-transition: none var(--control-transition);
    -o-transition: none var(--control-transition);
    transition-property: border-color, border-radius, opacity, box-shadow;
    -webkit-transition-property: border-color, border-radius, opacity, -webkit-box-shadow;
    -moz-transition-property: border-color, border-radius, opacity, -moz-box-shadow;
    -o-transition-property: border-color, border-radius, opacity, box-shadow;
}

.search-control .form-control
{
    padding-left: var(--control-search-padding);
    border-radius: calc(var(--control-height) / 2);
}

@media (hover: hover)
{
    .form-control:not(:disabled):hover
    {
        border-color: var(--color-control-border-hover);
    }
}

.form-control:focus,
.focus > .form-group > .form-control
{
    border-color: var(--color-control-border-focus) !important;
    -webkit-box-shadow: 0 0 0 1px inset var(--color-control-border-focus);
    -moz-box-shadow: 0 0 0 1px inset var(--color-control-border-focus);
    box-shadow: 0 0 0 1px inset var(--color-control-border-focus);
}

.form-validated .form-control.ng-invalid:focus,
.form-validated .ng-invalid > .form-group > .form-control:focus,
.form-validated .ng-invalid > .form-control:focus,
.form-validated .focus > .form-group > .form-control.ng-invalid,
.form-validated .ng-invalid.focus > .form-group > .form-control
{
    -webkit-box-shadow: 0 0 0 1px inset var(--color-invalid) !important;
    -moz-box-shadow: 0 0 0 1px inset var(--color-invalid) !important;
    box-shadow: 0 0 0 1px inset var(--color-invalid) !important;
}

.form-validated .form-control.ng-invalid,
.form-validated .ng-invalid > .form-group > .form-control,
.form-validated .ng-invalid > .form-control
{
    border-color: var(--color-invalid) !important;
}

.form-validated .form-control.ng-invalid + .control-label,
.form-validated .ng-invalid > .form-group > .form-control + .control-label,
.form-validated .ng-invalid > .form-control + .control-label,
.form-validated input[type=checkbox].ng-invalid + .checkmark::before,
.form-validated input[type=checkbox].ng-invalid + .checkmark::after
{
    color: var(--color-invalid) !important;
}

fieldset:disabled a,
fieldset:disabled .checkbox-container,
fieldset:disabled .radio-container,
fieldset:disabled select-list,
fieldset:disabled .file-input,
.form-control:disabled,
.disabled,
.form-control:disabled + .control-label,
.form-control:disabled + .control-label + .control-icon
{
    opacity: var(--disabled-opacity) !important;
    pointer-events: none;
}

fieldset:disabled
{
    pointer-events: none;
}

.form-control + .control-label
{
    white-space: nowrap;
    font-size: var(--body-font-size);
    font-weight: 400;
    color: var(--color-placeholder);
    background-color: transparent;
    pointer-events: none;
    position: absolute;
    top: var(--placeholder-top);
    left: var(--control-padding);
    padding: 0;
    transition: none var(--control-transition-linear);
    -webkit-transition: none var(--control-transition-linear);
    -moz-transition: none var(--control-transition-linear);
    -o-transition: none var(--control-transition-linear);
    transition-property: padding, color, opacity, transform;
    -webkit-transition-property: padding, color, opacity, -webkit-transform;
    -moz-transition-property: padding, color, opacity, -moz-transform;
    -o-transition-property: padding, color, opacity, -o-transform;
    transform-origin: top left;
    -webkit-transform-origin: top left;
    -moz-transform-origin: top left;
    -o-transform-origin: top left;
    cursor: text;
}

.search-control .form-control + .control-label
{
    left: var(--control-search-padding);
}

.form-control + .control-label::before
{
    content: '';
    position: absolute;
    left: 0;
    top: 30%;
    height: 70%;
    width: 100%;
    background-color: var(--color-control-background);
    border-radius: 0.5rem;
    z-index: -1;
}

.required::after
{
    content: ' *';
    color: var(--color-danger);
}

input.form-control:-webkit-autofill + .control-label,
input.form-control:not(:placeholder-shown) + .control-label,
input.form-control:focus + .control-label,
textarea.form-control:-webkit-autofill + .control-label,
textarea.form-control:not(:placeholder-shown) + .control-label,
textarea.form-control:focus + .control-label,
/*.form-group .form-control:active + .control-label,*/
.form-control.not-empty + .control-label,
.focus > .form-group > .form-control + .control-label,
.focus > .form-control + .control-label
{
    transform: translateY(calc(var(--label-transform-y))) scale(var(--label-scale));
    -webkit-transform: translateY(var(--label-transform-y)) scale(var(--label-scale));
    -moz-transform: translateY(var(--label-transform-y)) scale(var(--label-scale));
    -o-transform: translateY(calc(var(--label-transform-y))) scale(var(--label-scale));
    font-size: 1rem;
    padding: 0 0.2rem;
    font-weight: 500;
}

.form-control:focus + .control-label,
.focus > .form-group > .form-control + .control-label
{
    color: var(--color-primary);
}

.form-control.has-icon ~ .control-icon,
.form-control.clearable ~ .control-icon
{
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 var(--control-padding);
    font-size: 1.5rem;
    color: var(--color-control-icon);
    transition: none var(--control-transition);
    -webkit-transition: none var(--control-transition);
    -moz-transition: none var(--control-transition);
    -o-transition: none var(--control-transition);
    transition-property: color,opacity;
    -webkit-transition-property: color,opacity;
    -moz-transition-property: color,opacity;
    -o-transition-property: color,opacity;
}

.search-control .form-control.has-icon ~ .control-icon,
.search-control .form-control.clearable ~ .control-icon
{
    padding: 0 1.25rem;
}

.form-control.clearable ~ .control-icon.icon-cancel
{
    right: 0.25rem;
}

html.mobile .control-icon.press-icon:not(.icon-cancel)
{
    pointer-events: none;
}

.control-icon.press-icon,
.file-input
{
    cursor: pointer;
}

.form-control.has-icon,
.form-control.has-icon input,
.form-control.clearable,
.form-control.clearable input
{
    padding-right: 3.25rem;
}

.form-control.has-icon ~ .icon-search.control-icon
{
    font-size: 1.35rem;
}

.form-control.clearable.has-icon,
.form-control.clearable.has-icon input
{
    padding-right: 4.75rem;
}

.form-control.clearable ~ .control-icon.icon-cancel
{
    font-size: 0.8rem;
    color: var(--color-placeholder);
}

.form-control.clearable.has-icon ~ .control-icon.icon-cancel
{
    right: 2.5rem;
    padding-right: 0;
    margin-right: 1rem;
}

.form-control.clearable:focus ~ .control-icon.icon-cancel,
.focus > .form-group > .form-control.clearable ~ .control-icon.icon-cancel
{
    color: var(--color-invalid);
}

.invalid-feedback
{
    display: block;
    font-size: 0.8125rem;
    margin-top: 0.2rem;
    color: var(--color-danger);
}

.invalid-feedback.ng-animating
{
    overflow: hidden !important;
}

.form-group .invalid-feedback > div
{
    display: none;
}

.form-group .invalid-feedback > div:first-child
{
    display: block;
}

textarea
{
    resize: none;
    overflow-x: hidden;
    padding: 0.5rem var(--control-padding);
    max-height: 60vh;
}

.characters-counter,
.remark
{
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--color-placeholder);
}

clearable-input + text-characters-counter .characters-counter
{
    margin-top: 0.2rem;
}

.remark
{
    margin-top: 0.35rem;
}
