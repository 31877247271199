.dropdown
{
    position: relative;
}

.dropdown-container
{
    z-index: var(--dropdown-z-index);
    -webkit-box-shadow: var(--dropdown-shadow);
    -moz-box-shadow: var(--dropdown-shadow);
    box-shadow: var(--dropdown-shadow);
    position: absolute;
    top: 100%;
    right: 0;
    width: fit-content;
    background-color: var(--color-section-background);
    border-radius: var(--control-border-radius);
    border: 1px solid var(--color-dropdown-border);
    overflow: hidden;
}

.dropdown-container.ng-animating
{
    overflow: hidden !important;
}

.dropdown-container.body
{
    opacity: 0;
    -webkit-transition: none var(--control-transition);
    -moz-transition: none var(--control-transition);
    -o-transition: none var(--control-transition);
    transition: none var(--control-transition);
    -webkit-transition-property: opacity, -webkit-transform;
    -moz-transition-property: opacity, -moz-transform;
    -o-transition-property: opacity, -o-transform;
    transition-property: opacity, transform;
}

.dropdown-container.body.active
{
    opacity: 1;
    -webkit-transform: none;
    -moz-transform: none;
    -o-transform: none;
    transform: none;
}

.dropdown-container.left
{
    top: 0;
    right: calc(100% + 0.5rem);
}

.dropdown-container.top
{
    -webkit-box-shadow: var(--dropdown-shadow-up);
    -moz-box-shadow: var(--dropdown-shadow-up);
    box-shadow: var(--dropdown-shadow-up);
    bottom: calc(100% + 0.5rem);
    top: unset;
}
