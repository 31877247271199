.spinner-container
{
    position: relative;
}

.spinner-container .icon-spinner
{
    position: absolute;
    display: block;
    right: calc(100% + 0.5rem);
    top: 0.2rem;
}

.icon-spinner
{
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin
{
    to
    {
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin
{
    to
    {
        transform: rotate(360deg);
    }
}

@-o-keyframes spin
{
    to
    {
        transform: rotate(360deg);
    }
}

@keyframes spin
{
    to
    {
        transform: rotate(360deg);
    }
}
