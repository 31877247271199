.page-container
{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: var(--color-background);
}

.page-image-overlay::before,
.page-image
{
    background-image: url('images/roadmap.jpg');
}

.page-image-overlay::before
{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.65;
}

html.dark .page-image-overlay::before,
html.dark .page-image
{
    background-image: url('images/roadmap-dark.jpg');
}

.page-navigate-back
{
    position: absolute;
    top: var(--page-spacing);
    left: var(--page-spacing);
}

.page-navigate-back button
{
    font-size: 1.35rem;
    color: var(--color-text);
}

.page-navigate-back button i
{
    margin-right: 0.5rem;
}

.page-content
{
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 0;
    padding-top: 1rem;
    position: relative;
}

.page-content.mobile-standalone
{
    padding-top: 0;
    border-radius: var(--large-border-radius) var(--large-border-radius) 0 0;
    background-color: var(--color-background);
    border: 1px solid var(--color-primary);
    border-bottom: none;
}

.page-content.mobile-standalone .page-header
{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem var(--page-spacing) 0;
}

.page-content.mobile-standalone .page-header > i
{
    font-size: 1.5rem;
}

.page-content.mobile-standalone .page-header > button.back-button
{
    width: fit-content;
    font-weight: 600;
    border-bottom: 1px solid var(--color-text);
    margin-top: 0.25rem;
}

.page-content.mobile-standalone .page-header > button.back-button i
{
    margin-right: 0.5rem;
}

.page-section
{
    position: relative;
    background-color: var(--color-background);
    border-radius: var(--modal-border-radius);
    border: 1px solid var(--color-control-border);
}

.page-padding
{
    padding-right: var(--page-spacing);
    padding-left: var(--page-spacing);
}

.page-scroll-padding
{
    padding: 0 calc(var(--page-spacing) / 2 + var(--scrollbar-size) / 2);
    margin: 0 calc(var(--page-spacing) / 2 - var(--scrollbar-size) / 2);
}

.page-section-scroll-padding
{
    padding: 0 calc(var(--page-spacing) / 2 + var(--scrollbar-size) / 2);
    margin: 0 calc(0% - var(--page-spacing) / 2 - var(--scrollbar-size) / 2);
}

.page-section-padding
{
    padding: var(--section-padding) var(--page-spacing);
}
