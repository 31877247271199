.checkbox-container,
.radio-container
{
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    font-weight: 400 !important;
    -webkit-transition: none var(--control-transition);
    -moz-transition: none var(--control-transition);
    -o-transition: none var(--control-transition);
    transition: none var(--control-transition);
    -webkit-transition-property: -webkit-filter, border-color, background-color, color, opacity, -webkit-box-shadow;
    -moz-transition-property: -moz-filter, border-color, background-color, color, opacity, -moz-box-shadow;
    -o-transition-property: filter, border-color, background-color, color, opacity, box-shadow;
    transition-property: filter, border-color, background-color, color, opacity, box-shadow;
}

.checkbox-container:not(.no-text),
.radio-container:not(.no-text)
{
    padding-right: 0.5rem;
}

.checkbox-container input,
.radio-container input
{
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkbox-container .checkmark,
.radio-container .checkmark
{
    font-size: 1rem;
    position: relative;
    display: flex;
    align-items: center;
}

.checkbox-container:not(.no-text) .checkmark,
.radio-container:not(.no-text) .checkmark
{
    margin-right: 0.8rem;
}

.checkbox-container .checkmark::before,
.checkbox-container .checkmark::after,
.radio-container .checkmark::before,
.radio-container .checkmark::after
{
    content: '';
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition: opacity var(--control-transition);
    -moz-transition: opacity var(--control-transition);
    -o-transition: opacity var(--control-transition);
    transition: opacity var(--control-transition);
}

.checkbox-container .checkmark::after,
.radio-container .checkmark::after
{
    position: absolute;
    opacity: 0;
    left: 0;
}

.checkbox-container .checkmark::before
{
    content: '\e902';
}

.checkbox-container .checkmark::after
{
    content: '\e903';
}

.radio-container .checkmark::before
{
    content: '\e912';
}

.radio-container .checkmark::after
{
    content: '\e913';
}

.checkbox-container input:checked ~ .checkmark::before,
.radio-container input:checked ~ .checkmark::before
{
    opacity: 0;
}

.checkbox-container input:checked ~ .checkmark::after,
.radio-container input:checked ~ .checkmark::after
{
    opacity: 1;
}

.checkbox-container:has(input:disabled),
.radio-container:has(input:disabled)
{
    opacity: var(--disabled-opacity) !important;
    pointer-events: none;
}

.group-checkbox,
.group-radio
{
    margin-bottom: 1rem;
}

.checkbox-container .checkbox-switch
{
    width: 2.8rem;
    height: 1.6rem;
    border-radius: 0.8rem;
    background-color: var(--color-placeholder);
    margin-right: 0.8rem;
    position: relative;
    -webkit-transition: background-color var(--control-transition);
    -moz-transition: background-color var(--control-transition);
    -o-transition: background-color var(--control-transition);
    transition: background-color var(--control-transition);
}

.checkbox-container .checkbox-switch::before
{
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0.2rem;
    width: 1.2rem;
    top: 50%;
    height: 1.2rem;
    border-radius: 50%;
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
    transform: translate(0, -50%);
    background-color: var(--color-text-inverse);
    -webkit-transition: left var(--control-transition);
    -moz-transition: left var(--control-transition);
    -o-transition: left var(--control-transition);
    transition: left var(--control-transition);
}

.checkbox-container input:checked ~ .checkbox-switch
{
    background-color: var(--color-primary);
}

.checkbox-container input:checked ~ .checkbox-switch::before
{
    left: 50%;
}

@media (hover: hover)
{
    .checkbox-container:hover,
    .radio-container:hover
    {
        -webkit-filter: brightness(var(--control-hover-brightness));
        -moz-filter: brightness(var(--control-hover-brightness));
        filter: brightness(var(--control-hover-brightness));
    }
}
